<template>
    <div>
        <Quote/>
        <GoBack next="Kurs qo'shish"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
                <form class="ABC" @submit.prevent="addModule">
                    <div class="mb-3">
                        <label-component
                            label-for="course"
                            class="mb-1"
                        >
                            Kurs nomini kiriting
                        </label-component>
                        <input-component
                            model-name="name"
                            type="text"
                            id="course"
                            :model-form="module"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <label-component
                            label-for="courseDiscount"
                            class="mb-1"
                        >
                            Kurs chegirmasini kiriting
                        </label-component>
                        <input-component
                            model-name="discountPercent"
                            type="number"
                            id="courseDiscount"
                            :model-form="module"
                        />
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-4 text-end mt-3">
                            <button-component>Saqlash</button-component>
                        </div>
                    </div>
                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Kurs muvaffaqiyatli qo'shildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import accessMixin from "@/mixin/accessMixin";
import {mapActions} from "vuex";
import ButtonComponent from "@/components/ButtonComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import InputComponent from "@/components/InputComponent.vue";

export default {
    name: "Add-CoursePage",
    mixins: [accessMixin],
    components: {ButtonComponent, GoBack, Quote, LabelComponent, InputComponent},
    methods: {
        ...mapActions(['fetchAddModule']),
        addModule() {
            this.fetchAddModule({
                name: this.module.name,
                discountPercent: parseInt(this.module.discountPercent)
            })
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                })
                .catch(() => {
                    console.log('addModule da xato')
                })
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/add-course')
        }
    },
    data() {
        return {
            module: {
                name: '',
                discountPercent: ''
            },
        }
    },
}
</script>

<style scoped>

* {
    padding: 0;
    margin: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

section form {
    font-family: Comfortaa, cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;


}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>
